import React, { useState, useEffect } from 'react';
import './MessyMovingGrid.css';

const MAX_VISIBLE_IMAGES = 4;
const MIN_VISIBLE_TIME = 3000; // 2 seconds in milliseconds

const MessyMovingGrid = ({ images }) => {
  const [imageProps, setImageProps] = useState([]);

  useEffect(() => {
    // Initialize images with random positions
    const initialImageProps = images.map((src) => ({
      src,
      top: Math.random() * window.innerHeight,
      left: Math.random() * window.innerWidth,
      visible: false,
      interval: Math.random() * 2000 + 1000,
      lastUpdate: Date.now(),
      visibleSince: 0, // Track when image became visible
    }));

    // Randomly select initial visible images
    const shuffled = [...initialImageProps].sort(() => Math.random() - 0.5);
    for (let i = 0; i < MAX_VISIBLE_IMAGES; i++) {
      shuffled[i].visible = true;
      shuffled[i].visibleSince = Date.now();
    }
    
    setImageProps(shuffled);

    const interval = setInterval(() => {
      const currentTime = Date.now();
      setImageProps(prevProps => {
        const updatedProps = prevProps.map(prop => {
          if (currentTime - prop.lastUpdate > prop.interval) {
            return {
              ...prop,
              top: Math.random() * window.innerHeight,
              left: Math.random() * window.innerWidth,
              interval: Math.random() * 2000 + 1000,
              lastUpdate: currentTime,
            };
          }
          return prop;
        });

        // Only swap images that have been visible for at least MIN_VISIBLE_TIME
        const visibleCount = updatedProps.filter(p => p.visible).length;
        if (visibleCount < MAX_VISIBLE_IMAGES) {
          const hiddenImages = updatedProps.filter(p => !p.visible);
          const toShow = hiddenImages[Math.floor(Math.random() * hiddenImages.length)];
          if (toShow) {
            toShow.visible = true;
            toShow.visibleSince = currentTime;
          }
        } else if (Math.random() > 0.7) { // 30% chance to swap visible images
          const visibleImages = updatedProps.filter(p => 
            p.visible && (currentTime - p.visibleSince) > MIN_VISIBLE_TIME
          );
          const hiddenImages = updatedProps.filter(p => !p.visible);
          
          if (visibleImages.length > 0 && hiddenImages.length > 0) {
            const visibleImage = visibleImages[Math.floor(Math.random() * visibleImages.length)];
            const hiddenImage = hiddenImages[Math.floor(Math.random() * hiddenImages.length)];
            
            visibleImage.visible = false;
            hiddenImage.visible = true;
            hiddenImage.visibleSince = currentTime;
          }
        }

        return updatedProps;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="messy-moving-grid">
      {imageProps.map((image, index) => (
        <img
          key={index}
          src={image.src}
          style={{
            position: 'absolute',
            top: `${image.top}px`,
            left: `${image.left}px`,
            display: image.visible ? 'block' : 'none',
            width: '400px',
            height: '400px',
            objectFit: 'cover',
          }}
          alt="Random"
        />
      ))}
    </div>
  );
};

export default MessyMovingGrid;