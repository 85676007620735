import React, { useEffect, useState } from "react";
import './App.css';
import MessyMovingGrid from './components/MessyMovingGrid';
import BASE_URL from './config';


const images = [
   `${BASE_URL}/assets/photos/2022/IMG_4741.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4918.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5420.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5516.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5800.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6081.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6329.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6538.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6789.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7667.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8033.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8603.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4796.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4941.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5424.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5535.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5802.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6167.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6332.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6541.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6989.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7754.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8131.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8626.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4844.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5031.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5428.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5539.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5855.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6200.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6371.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6624.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7038.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7970.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8132.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4845.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5173.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5458.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5568.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5899.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6287.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6404.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6735.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7087.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7999.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8194.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4857.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5321.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5496.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5761.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6010.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6288.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6405.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6747.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7442.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8001.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8514.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_4874.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5417.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5514.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_5781.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6075.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6315.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6533.MOV.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_6774.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_7517.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8027.MP4.gif`,
 `${BASE_URL}/assets/photos/2022/IMG_8602.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_0059.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_2901.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3533.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_4074.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_0243.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3136.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3624.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_8745.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_0438.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3177.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3658.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_9580.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3254.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3723.mov.gif`,
  `${BASE_URL}/assets/photos/2023/video_1_b7022e1c486940d7be3c6b2eb234915b.mp4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_0587.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3351.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3928.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_0934.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3449.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3962.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_2117.MOV.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_3530.MP4.gif`,
  `${BASE_URL}/assets/photos/2023/IMG_4026.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0056.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0413.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_1316.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_3454.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4094.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4182.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4267.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_5033.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0082.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0460.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_1440.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_3930.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4104.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4202.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4306.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_5062.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0235.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0492.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_1446.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_3998.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4124.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4207.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4332.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_5147.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0251.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0510.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_2095.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4088.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4133.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4209.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4356.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_5372.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0267.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0747.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_2451.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4090.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4147.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4212.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4576.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0399.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_0980.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_3073.MOV.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4091.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4160.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4255.MP4.gif`,
  `${BASE_URL}/assets/photos/2024/IMG_4729.MOV.gif`,
]



function App() {
  const [backgroundImage, setBackgroundImage] = useState("");
  // Convert from const to state
  const [useBlackBackground, setUseBlackBackground] = useState(false);
  const [showGrid, setShowGrid] = useState(true);

  useEffect(() => {
    if (!useBlackBackground) {
      const changeBackground = () => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setBackgroundImage(randomImage);
      };

      changeBackground();
      const intervalId = setInterval(changeBackground, 4000);

      return () => clearInterval(intervalId);
    }
  }, [useBlackBackground]); // Add dependency

  const handleStart = () => {
    setUseBlackBackground(false);
    setShowGrid(true);
  };

  const handleStop = () => {
    setUseBlackBackground(true);
    setShowGrid(false);
  };

  return (
    <div className="App">
      {/* <NavBar /> */}
      <div
        className="fullscreen-background"
        style={{
          backgroundColor: useBlackBackground ? 'black' : undefined,
          backgroundImage: useBlackBackground ? 'none' : `url(${backgroundImage})`
        }}
      >
        {showGrid && <MessyMovingGrid images={images} />}
      </div>
      <div className="container">
        <div className="header">
          <div className="header-content">
            <h1>PPVMIO</h1>
            <h4>Paul Pelayo:: New York, NY</h4>
          </div>
        </div>
        <div className="choice-buttons">
          <button
            onClick={handleStop}
            disabled={useBlackBackground}
            className={`bubble-choice ${useBlackBackground ? 'active' : ''}`}
          >
            This is too fucking crazy
          </button>
          <button
            onClick={handleStart}
            disabled={!useBlackBackground}
            className={`bubble-choice ${!useBlackBackground ? 'active' : ''}`}
          >
            Actually I love it
          </button>
        </div>
        <p>
          <i>It's pronounced pih-mee-o (like vimeo)</i>
        </p>
        <p>
          PPVMIO is a Brooklyn-based creative technologist who is currently exploring the intersection of his profession as a software engineer and his own interest in art and design. Behind the scenes, he looks to create projects with the same tools and frameworks that are foundational to developing large-scale web applications for tech giants. By repurposing this technology, he not only probes the conventional applications of these tools but also harnesses their robust capabilities to question the role software engineering and by extension "tech" has played in shaping modern society.
        </p>

        <p>Explore:</p>
        <ul>
          <li><a href="https://proto.ppvm.io/" target="_blank" rel="noopener noreferrer">Where it all began</a></li>
          <li><a href="https://oojeehs.ppvm.io" target="_blank" rel="noopener noreferrer">Love Letter</a></li>
          <li><a href="https://ineedyou.ppvm.io" target="_blank" rel="noopener noreferrer">Password is bball123</a></li>
          <li><a href="https://soundcloud.com/pelayo-paul/sets/coping-mechanisms-for-quarantine" target="_blank" rel="noopener noreferrer">Coping mechanisms from quarantine</a></li>
          <li><a href="https://anxiety.ppvm.io/" target="_blank" rel="noopener noreferrer">Kinda cringe but sometimes it's necessary</a></li>
        </ul>
        <p>Find me:</p>
        <ul>
          <li><a href="https://github.com/PPVMIO" target="_blank" rel="noopener noreferrer">Github</a></li>
          <li><a href="http://linkedin.ppvm.io" target="_blank" rel="noopener noreferrer">Linkedin</a></li>
        </ul>
      </div>
    </div>
  );
}

export default App;
